import React, { useContext } from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';

import imgDefault from '../../assets/image/placeholder.png'


const BlockSeven = ({ title, text, listsAbout, listsServices, image1, image2, image3, image4, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#fff] pt-[150px] pb-[200px]'>
            <div className='w-[95%] md:w-[90%] mx-auto'>
                <div className='flex flex-col-reverse md:flex-row-reverse px-1 md:px-10 relative'>
                    {/* <div className='w-[1300px] h-[1300px] absolute bg1 rounded-full left-[-750px] bottom-[-850px]'></div> */}

                    <div className='w-full mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-12 flex flex-col justify-center text-center md:text-start'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed ?
                                    <h2 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h3 className='pb-10 capitalize'>
                                        {
                                            rpdata?.dbPrincipal?.exprYears ?
                                                `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                                : 'we have many years of experience'
                                        }
                                    </h3>
                                :
                                <h2 className='pb-3 capitalize'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <BsArrowReturnRight />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listsServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <BsArrowReturnRight />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }

                        <div>
                            <ButtonContent />
                        </div>
                    </div>
                    <div className=' w-full h-auto  lg:w-[90%] md:-my-20 md:w-[50%] z-10'>
                        <div className='w-full h-1/2 flex items-center justify-center space-x-4 mb-2'>
                            <div
                                className='self-center justify-self-center w-[160px] rounded-[50%] border-white border-[8px] shadow-sm md:w-[280px] h-[160px] md:h-[280px] row-start-2  bg-center bg-cover'
                                style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}>
                            </div>
                            <div

                                className='self-end justify-self-center rounded-[15px]  lg:block w-[180px] border-white border-[8px] shadow-sm lg:w-[300px] h-[180px] md:w-[200px] md:h-[200px] lg:h-[300px] col-start-2 bg-center bg-cover'
                                style={{ backgroundImage: `url("${image2 ? image2 : imgDefault}")` }}>

                            </div>
                        </div>
                        <div className='w-full h-1/2 flex items-center justify-center space-x-4'>
                            <div

                                className='self-start justify-self-end rounded-[15px]  lg:block border-white border-[8px] shadow-sm w-[180px] md:w-[300px] h-[180px] md:h-[300px]  bg-center bg-cover'
                                style={{ backgroundImage: `url("${image3 ? image3 : imgDefault}")` }}>

                            </div>
                            <div

                                className='self-center justify-self-center rounded-[50%] lg:block border-white border-[8px] shadow-sm w-[150px] md:w-[280px] h-[150px] md:h-[280px] lg:row-start-1 bg-center bg-cover'
                                style={{ backgroundImage: `url("${image4 ? image4 : imgDefault}")` }}>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}


export default BlockSeven;